import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import HiringImage from "../../assets/updatedIllustrations/Snapbrillia_business_2womenillustration.png";
import BountiesButton from '../../shared/buttons/bounties';
import 'bootstrap/dist/css/bootstrap.css';
import '../../shared/css/typography.css';

const BusinessHiring = () => {
    return (
      <Container fluid className="mt-5 mb-5">
        <Row className="mt-5">
          <Col xs={12} md={5} className="ps-5">
            <img src={HiringImage} alt="Snapbrillia Hiring" className="img-fluid"/>
          </Col>
          <Col xs={12} md={7} className="mt-3 mb-5 ps-5">
            <h2 className="mb-4 bold">Hire Top Performers</h2>
            <h4 className="sub-text mb-5">
            Effortlessly hire proven and trusted talent quickly who have worked with your code base and tech stack. Our on-chain proof of identity and reputation outperforms traditional resumes and coding assessments and delivers real verified people who are true performers.            </h4>
            <a href="https://explore.snapbrillia.com/opportunities" className="social">
              <BountiesButton />
            </a>
          </Col>
        </Row>
        
      </Container>
    );
  };
  
  export default BusinessHiring;