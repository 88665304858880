import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import EarnImage from "../../assets/illustrations/snapbrillia_astronaut_illustration.png";
import FindGigButton from "../../shared/buttons/findGigs";
import "bootstrap/dist/css/bootstrap.css";
import "../../shared/css/typography.css";
import "../../shared/css/style.css";

const EarnHomePage = () => {
  return (
    <Container fluid className="mt-6 mb-5">
      <Row className="mt-6">
        <Col xs={12} md={5} className="ps-5">
          <img src={EarnImage} alt="Snapbrillia Gigs" className="img-fluid" />
        </Col>
        <Col xs={12} md={7} className="mt-6 mb-2 ps-5">
          <h2 className="mb-4 bold">Try Working Together Before Committing</h2>
          <h3 className="sub-text mb-5 lh-sm">
            Build your team with people who you enjoy working with and who deliver on the work. Skip screening 
            calls, tests, and hard-skill interviews. Save your time and still find 10x performers. Hit your goals 
            and establish a culture of belonging and success.
          </h3>
          <a
            href="https://explore.snapbrillia.com"
            target="_blank"
            className="social"
            rel="noreferrer"
          >
            <FindGigButton />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default EarnHomePage;
