import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import AnalyticsImage  from "../../assets/illustrations/snapbrillia_analytics_illustration.png";
import 'bootstrap/dist/css/bootstrap.css';
import '../../shared/css/typography.css';

const HiringAnalytics = () => {
    return (
      <Container fluid>
        <Row className="mt-5">
          <Col xs={12} md={7} className="mt-3 mb-2 ps-5">
            <h2 className="mb-4 bold">Transparent Hiring Analytics</h2>
            <h4 className="sub-text mb-5">
            Built for enterprise diversity, equity, &amp; inclusion. Candidates’ EEO data is captured to help you with enterprise compliance.
            </h4>
          </Col>
          <Col xs={12} md={5} className="ps-5 mb-5">
            <img src={AnalyticsImage} alt="Snapbrillia Community" className="img-fluid"/>
          </Col>
        </Row>
        
      </Container>
    );
  };
  
  export default HiringAnalytics;