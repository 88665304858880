import { Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import '../css/about.css';
import '../../shared/css/typography.css';


function AboutHero() {
  return (
    <div className="About">
      <Container fluid className="about_bg">
        <Row className="gy-0 aboutHero1">
          <Col xs={12} sm={12} md={11} lg={10} xl={8} xxl={8} className="mt-3 mb-2">
          <h1 className="primary-text-color bold lg-text ps-4">
              Get 1 million underrepresented better access to opportunities
            </h1>
          </Col>
        </Row>
        <Row className="gy-0 aboutHero">
        </Row>
      </Container>
    </div>
  );
}

export default AboutHero;