import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import HeroImage from "../../assets/illustrations/snapbrillia_desk_hero.png";
import { ReactComponent as ChevronDown } from "../../assets/icons/snapbrillia_chevron_down_icon.svg";
import "bootstrap/dist/css/bootstrap.css";
import "../css/homePage.css";

const HomepageHero = (homedata) => {
  const Headline = "Onboard Brilliant Workers in Days";
  const SubHeader = "Get the help you need from the community you can trust.";
  const Hero_Image = HeroImage;
  return (
    <Container fluid className="mb-5 home_bg">
      <Row className="gy-0">
        <Col md={12} className="mt-4 mb-2 ps-5">
          <h1 className="primary-text-color bold">{Headline}</h1>
        </Col>
        <Col xs={12} md={8} className="ps-5">
          <h4 className="primary-text-color">{SubHeader}</h4>
        </Col>
      </Row>
      <Row className="gy-0 mt-0 mb-0">
        <Col md={12} className="text-center mt-n4">
          <img src={Hero_Image} alt="snapbrillia hero" className="img-fluid" />
        </Col>
      </Row>
      <Row className="align-self-center mt-0 d-none d-sm-block">
        <Col md={12} className="text-center mb-0">
          <p className="mb-0">
            <small>scroll down to learn more</small>
          </p>
        </Col>
        <Col md={12} className="text-center mt-0">
          <ChevronDown className="arrow-bounce" />
        </Col>
      </Row>
    </Container>
  );
};

export default HomepageHero;
