import AboutHero from "./components/aboutHero";
import AboutUs from "./components/aboutUs";
import TeamSection from "./components/teamSection";
import JoinCommunity from "./components/joinCommunity";
import FooterHomePage from "../shared/footer";
import 'bootstrap/dist/css/bootstrap.css';
import './css/about.css';
import '../shared/css/typography.css';
import { Helmet } from 'react-helmet';


const About = () => {
  return (
    <>
    <Helmet>
        <title>Mission and Vision | About Snapbrillia</title>
        <meta
        name="description"
        property="og:description"
        content="Discover the vision and mission behind Snapbrillia, the global open-source ecosystem for collaboration, knowledge-sharing, and innovative solutions for global hiring, job boards, and DEI recruiting strategies."
        />
    </Helmet>
    <AboutHero />
    <AboutUs />
    <TeamSection />
    <JoinCommunity />
    <FooterHomePage />
    </>
  );
}

export default About;