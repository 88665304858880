import CommunityHeader from "../communityPage/components/communityHero";
import SocialNetworks from "../communityPage/components/socialNetworks";
import Footer from "../shared/footer";
import 'bootstrap/dist/css/bootstrap.css';
import './css/community.css';
import { Helmet } from 'react-helmet';

const Community = () => {
  return (
    <>
    <Helmet>
        <title>Connect with Global Professionals | Snapbrillia Community</title>
        <meta
        name="description"
        property="og:description"
        content="Join the Snapbrillia community and connect with diverse professionals from around the world via social networks. Access innovative solutions for collaboration, global hiring, job boards, and DEI recruiting."
        />
    </Helmet>
    <CommunityHeader/>
    <SocialNetworks/>
    <Footer/>
    </>
  );
}

export default Community;