import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PublicImage from "../../assets/updatedIllustrations/Snapbrillia_homepage_rocketmanillustration.png";
import CreateGigButton from "../../shared/buttons/createGig";
import "bootstrap/dist/css/bootstrap.css";
import "../../shared/css/typography.css";
import "../../shared/css/style.css";

const FundPublicHomePage = () => {
  return (
    <Container fluid className="mt-6 mb-5">
      <Row className="mt-6">
        <Col xs={12} md={5} className="ps-5">
          <img src={PublicImage} alt="Snapbrillia Fund" className="img-fluid" />
        </Col>
        <Col xs={12} md={7} className="mt-6 mb-2 ps-5">
          <h2 className="mb-4 bold">Vetted with Proven Track Records</h2>
          <h3 className="sub-text mb-5 lh-sm">
            Have a peace of mind with our 99% matching success rate with vetted and verified 
            professionals. Quickly simplify your hiring, onboarding, and payroll.
          </h3>
          <a
            href="https://business.snapbrillia.com/"
            target="_blank"
            className="social"
            rel="noreferrer"
          >
            <CreateGigButton />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default FundPublicHomePage;
