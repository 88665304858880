import { Container, Row, Col } from "react-bootstrap";
import ExploreButton from '../../shared/buttons/explore';
import 'bootstrap/dist/css/bootstrap.css';
import '../css/about.css';
import '../../shared/css/typography.css';


function AboutUs() {
  return (
    <div className="About">
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={6} className="mt-n4 mb-2 ps-5">
            <h2 className="mb-3 bold">About Us</h2>
            <h4 className="sub-text">
              Snapbrillia is an inclusive hiring system that creates fair opportunities and empowers your teams to hire the best people faster regardless of who they are. We provide diversity analytics and an unbiased hiring platform for enterprise businesses and fast-growing startups.
            </h4>
          </Col>
          <Col xs={12} sm={12} md={6} className="pe-5 mt-5 mb-2 ps-5">
            <h4 className="sub-text mb-5">
              We at Snapbrillia provide a space for people to grow their skills and participate in building the open-source web and other public goods and be invited to participate in exclusive projects.
            </h4>
            <a href="https://www.producthunt.com/upcoming/snapbrillia" target="_blank" rel="noreferrer" className="social d-none">
                <ExploreButton />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutUs;