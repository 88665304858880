import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import LearnGrowImage from "../../assets/updatedIllustrations/Snapbrillia_homepage_couchillustration.png";
import BountiesButton from "../../shared/buttons/bounties";
import "bootstrap/dist/css/bootstrap.css";
import "../../shared/css/typography.css";
import "../../shared/css/style.css";

const LearnGrowHomePage = () => {
  return (
    <Container fluid className="mt-6 mb-5">
      <Row className="mt-6 mb-5">
        <Col xs={12} md={7} className="mt-6 mb-4 ps-5">
          <h2 className="mb-4 bold">Work, Learn, and Grow Together</h2>
          <h3 className="sub-text mb-5 lh-sm">
            The future of work is remote, flexible, and sustainable. Join our global talent pool 
            of doers, earn experience others are looking for, and work with projects you believe in. 
            Leave an imprint on your organization and the lives of others.
          </h3>
          <a
            href="https://explore.snapbrillia.com/opportunities"
            target="_blank"
            className="social"
            rel="noreferrer"
          >
            <BountiesButton />
          </a>
        </Col>
        <Col xs={12} md={5} className="ps-5">
          <img
            src={LearnGrowImage}
            alt="Snapbrillia Learn and Grow"
            className="img-fluid"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default LearnGrowHomePage;
