import React from "react";
import HeroHomePage from "../homePage/components/heroImage";
import BountiesHomePage from "../homePage/components/bounties";
import GetFundedHomePage from "../homePage/components/getFunded";
import LearnGrowHomePage from "../homePage/components/learnGrow";
import FundPublicHomePage from "../homePage/components/fundPublic";
import HiringHomePage from "../homePage/components/hiring";
import EarnHomePage from "../homePage/components/earnGrow";
import FooterHomePage from "../shared/footer";
import 'bootstrap/dist/css/bootstrap.css';
import './css/homePage.css';
import { Helmet } from 'react-helmet';

const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>Inclusive Hiring System | Snapbrillia</title>
                <meta
                name="description"
                property="og:description"
                content="Snapbrillia, the global open-source ecosystem for collaboration, knowledge-sharing, global hiring, DEI recruiting, identity wallet, and grant fundraising."
                />
            </Helmet>
            <HeroHomePage />
            <BountiesHomePage />
            <GetFundedHomePage />
            <FundPublicHomePage />
            <LearnGrowHomePage />
            <EarnHomePage />
            <HiringHomePage />
            <FooterHomePage />
        </>
    );
  };
  
  export default HomePage;