import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import FundPublic  from "../../assets/illustrations/snapbrillia_faster_illustration.png";
import StartButtonBounce from '../../shared/buttons/getStartedBounce';
import 'bootstrap/dist/css/bootstrap.css';
import '../../shared/css/typography.css';

const BusinessClosingArg = () => {
    return (
      <Container fluid className="mt-5 mb-5">
        <Row className="mt-5">
          <Col xs={12} md={5} className="ps-5">
            <img src={FundPublic} alt="Snapbrillia Community" className="img-fluid"/>
          </Col>
          <Col xs={12} md={7} className="mt-3 mb-2 ps-5">
            <h4 className="sub-text mb-5">
                Build faster with hiring gigs that 10X your diversity throughput. Hire performers faster regardless of who they are.
            </h4>
            <a href="https://business.snapbrillia.com" className="social">
              <StartButtonBounce  />
            </a>
          </Col>
        </Row>
        
      </Container>
    );
  };
  
  export default BusinessClosingArg;