import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css'
import App from './App';
import Community from './communityPage/community';
import About from './aboutPage/about';
import Business from './businessPage/business';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './shared/scrollToTop';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import NavBar from './shared/navBar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <NavBar />
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/about" element={<About />} />
      <Route path="/business" element={<Business />} />
      <Route path="/community" element={<Community />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
