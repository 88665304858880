import { Nav, Navbar, Container } from "react-bootstrap";
import SnapbrilliaLogo from "../assets/logo/snapbrillia_logo.svg"
import 'bootstrap/dist/css/bootstrap.css';
import {LinkContainer} from 'react-router-bootstrap';
import './css/navBar.css'

function navBar() {
  return (
    <div className="navigation">
      <Navbar collapseOnSelect expand="lg" bg="light" className="pt-0 pb-0">
        <Container fluid>
          <LinkContainer to="/">
            <Navbar.Brand href=""><img src={SnapbrilliaLogo} alt="Snapbrillia Beta Logo" className="img-fluid logo"/></Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-end flex-grow-1 pe-5">
              <LinkContainer to="/">
                <Nav.Link to="/" className="ms-0 me-3">Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link to="/about" className="mx-3">About</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/business">
                <Nav.Link href="/business" className="mx-3">Business</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/community">
                <Nav.Link href="/community" className="mx-3">Community</Nav.Link>
              </LinkContainer>
              <Nav.Link href="https://explore.snapbrillia.com/opportunities" className="mx-3">Explore <span className="text-small"></span></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default navBar;
