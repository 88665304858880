import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import CommunityHeroImage from "../../assets/illustrations/snapbrillia_community_hero_illustration.png";
import JoinButton from '../../shared/buttons/join';
import 'bootstrap/dist/css/bootstrap.css';

const CommunityHero = () => {
    return (
      <Container fluid className="mb-5">
        <Row className="gy-0">
          <Col md={8} className="mt-3 mb-2 ps-5">
            <h1 className="primary-text-color bold">
                Join The Community
            </h1>
            <h3 className="primary-text-color mb-5">
                Our community is everything to us. Your participation, your voices, and your perspectives matter.
            </h3>
            <a href="https://discord.gg/vJuPXu86vS" target="_blank" rel="noreferrer" className="social">
              <JoinButton className="float-end" />
            </a>
          </Col>
          <Col xs={12} md={4} className="ps-5 mt-3">
            <img src={CommunityHeroImage} alt="Snapbrillia Community" className="img-fluid"/>
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default CommunityHero;