import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import '../../shared/css/typography.css';

const BusinessBounties = () => {
    return (
      <Container fluid className="mt-5 mb-5">
        <Row className="mt-5 mb-5">
          <Col xs={12} md={12} className="mt-5 mb-5 ps-5">
            <h2 className="mb-4 bold">Create Gigs in No Time</h2>
            <h4 className="sub-text">
            List gigs with a couple of quick and easy steps or delegate this to someone who can. Engage a broader community of talent who compete by working on your gigs on your budget, and to the best of their abilities.
            </h4>
          </Col>
        </Row>
        
      </Container>
    );
  };
  
  export default BusinessBounties;