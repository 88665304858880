import BusinesspageHero from "../businessPage/components/heroBusiness";
import BuiltFor from "../businessPage/components/builtFor";
import BusinessBounties from "../businessPage/components/businessBounties";
import BusinessHiring from "../businessPage/components/businessHire";
import HiringAnalytics from "../businessPage/components/hiringAnalytics";
import BusinessClosingArg from "../businessPage/components/businessClosingArg";
import Footer from "../shared/footer";
import 'bootstrap/dist/css/bootstrap.css';
import { Helmet } from 'react-helmet';

const BusinessPage = () => {
    return (
        <>
            <Helmet>
                <title>DEI recruiting strategies | Snapbrillia Business</title>
                <meta
                name="description"
                property="og:description"
                content="Snapbrillia Business offers innovative solutions for global hiring, job boards, and DEI recruiting strategies. Connect with a diverse network of professionals and build a strong, diverse workforce with Snapbrillia Business. Join us today."
                />
            </Helmet>
            <BusinesspageHero />
            <BuiltFor />
            <BusinessBounties />
            <BusinessHiring />
            <HiringAnalytics />
            <BusinessClosingArg />
            <Footer />
        </>
    );
  };
  
  export default BusinessPage;