import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import HiringImage from "../../assets/illustrations/snapbrillia_join_community_illustration.png";
import GrantsButton from "../../shared/buttons/grants";
import "bootstrap/dist/css/bootstrap.css";
import "../../shared/css/typography.css";
import "../../shared/css/style.css";

const HiringHomePage = () => {
  return (
    <Container fluid className="mt-6 mb-5">
      <Row className="mt-6">
        <Col xs={12} md={7} className="mt-6 mb-2 ps-5">
          <h2 className="mb-4 bold">Crowdfund Projects Impacting the World</h2>
          <h3 className="sub-text mb-5 lh-sm">
            We quadratically match your crowdfunding campaigns with equity-free funding to help your 
            projects meet more brilliant workers and impact more global communities. Let's create more 
            opportunities together for people and founders from around the world.
          </h3>
          <a
            href="https://explore.snapbrillia.com/grants"
            target="_blank"
            className="social"
            rel="noreferrer"
          >
            <GrantsButton />
          </a>
        </Col>
        <Col xs={12} md={5} className="ps-5">
          <img src={HiringImage} alt="Snapbrillia Gigs" className="img-fluid" />
        </Col>
      </Row>
    </Container>
  );
};

export default HiringHomePage;
