import React, {useState} from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Figure from 'react-bootstrap/Figure';
import Stack from 'react-bootstrap/Stack';
import 'bootstrap/dist/css/bootstrap.css';
import '../css/about.css';
import '../../shared/css/typography.css';
import Ninh from '../../assets/team/ninh.jpeg';
import Beatrice from '../../assets/team/beatrices.png';
//import Aklilu from '../../assets/team/aklilu.jpeg';
//import Barath from '../../assets/team/barath.png';
//import Asikur from '../../assets/team/asikur.jpeg';
import Dennis from '../../assets/team/dennis.png';
//import Derrique from '../../assets/team/derrique.jpeg';
import Paul from '../../assets/team/Paul.png';
//import Larry from '../../assets/team/larry.jpeg';
//import Han from '../../assets/team/han.png';
import kirti from '../../assets/team/kirti.jpg';
//import Jessica from '../../assets/team/jessica.png';
//import Rabin from '../../assets/team/rabin.jpeg';
//import Jeremy from '../../assets/team/jeremy.png';
//import Shaishav from '../../assets/team/shaishav.jpeg';
//import Vivian from '../../assets/team/vivian.png';
// import Yvette from '../../assets/team/yvette.jpeg';
// import Wisdom from '../../assets/team/wisdom.png';
// import Ivan from '../../assets/team/ivan.png';
// import JP from '../../assets/team/jp.png';
import James from '../../assets/team/james.jpeg';
//import Alice from '../../assets/team/alice.png';
import kevin from '../../assets/team/kevin.jpg';
import gladys from '../../assets/team/gladys kiboro.jpg';
import Messager from '../../assets/team/messager.png';
import kelvin from '../../assets/team/kelvin.png';
import Josephine from '../../assets/team/Josephine Wells.png';
import Roshni from '../../assets/team/Roshnii.png';
import zoe from '../../assets/team/zoe.jpg';
import bolade from '../../assets/team/boladee.png';
import Ernest from '../../assets/team/Ernest M..jpg';
import Justin from '../../assets/team/justin.png';
import Topher from '../../assets/team/topher.jpeg';
import RinaOtey from '../../assets/team/RinaOtey.png';


function TeamSection() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="About">
      <Container fluid>
        <Row>
          <Col md={12} className="mt-5 mb-2 ps-5">
            <h2 className="mb-4 bold">Meet the driving force of Snapbrillia</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3 mb-2 ps-5">
            <Row className="mb-5">
                <Col md={4}>
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item"><Figure className="d-inline">
                    <Figure.Image
                      width={80}
                      height={80}
                      alt="Ninh Tran"
                      src={Ninh}
                      roundedCircle
                      className="d-inline"
                    />
                  </Figure></li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4>Ninh Tran</h4></div>
                    <div>
                      <p className="text-muted small" onClick={handleShow}>CEO and Co-Founder</p>
                    </div>
                  </Stack></li>
                </ul>
                <Row className="mt-0">
                  <Col md={12}>
                    <Figure.Caption>
                        <em>"As a child I wanted to be, a garbage truck driver"</em>
                    </Figure.Caption>
                  </Col>
                </Row>
                </Col>
                <Col md={4}>
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item"><Figure className="d-inline">
                    <Figure.Image
                      width={80}
                      height={80}
                      alt="Beatrice Thelisma"
                      src={Beatrice}
                      roundedCircle
                      className="d-inline"
                      />
                    </Figure>
                  </li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4>Beatrice Thelisma</h4></div>
                    <div><p className="text-muted small">CTO and Co-Founder</p></div>
                  </Stack></li>
                </ul>
                <Row>
                  <Col md={12}>
                    <Figure.Caption>
                      <em>"As a child I wanted to be, a business woman"</em>
                    </Figure.Caption>
                  </Col>
                </Row>
                </Col>
                <Col md={4}>
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item"><Figure className="d-inline">
                    <Figure.Image
                      width={80}
                      height={80}
                      alt="Paul Albanese"
                      src={Paul}
                      roundedCircle
                      className="d-inline"
                    />
                  </Figure></li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4>Paul Albanese</h4></div>
                    <div><p className="text-muted small">CSO and Co-Founder</p></div>
                  </Stack></li>
                </ul>
                <Row>
                  <Col md={12}>
                    <Figure.Caption>
                      <a href="https://youkevin.be/HPPj6viIBmU" target="_blank" rel="noreferrer" className="text-decoration-none hidden-link">
                        <em>"As a child I wanted to be, a jedi"</em>
                      </a>
                    </Figure.Caption>
                  </Col>
                </Row>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col md={4}>
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item"><Figure className="d-inline">
                    <Figure.Image
                      width={80}
                      height={80}
                      alt="James Willingham"
                      src={James}
                      roundedCircle
                      className="d-inline"
                    />
                  </Figure></li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline"> 
                    <div><h4>James Willingham</h4></div>
                    <div><p className="text-muted small">CIO and Co-Founder</p></div>
                  </Stack></li>
                </ul>
                <Row className="mt-0">
                  <Col md={12}>
                    <Figure.Caption>
                      <em>"As a child I wanted to be, a man who tried everything"</em>
                    </Figure.Caption>
                  </Col>
                </Row>
                </Col>
                <Col md={4}>
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item"><Figure className="d-inline">
                    <Figure.Image
                      width={80}
                      height={80}
                      alt="Dennis Makuyev"
                      src={Dennis}
                      roundedCircle
                      className="d-inline"
                    />
                  </Figure></li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4>Dennis Makuyev</h4></div>
                    <div><p className="text-muted small">Design Team</p></div>
                  </Stack></li>
                </ul>
                <Row>
                  <Col md={12}>
                    <Figure.Caption>
                      <a href="https://bootcamp.uxdesign.cc/make-your-shadows-awesome-efd308762bc4" target="_blank" rel="noreferrer" className="text-decoration-none hidden-link">
                        <em>"As a child I wanted to be, a drop shadow expert"</em>
                      </a>
                    </Figure.Caption>
                  </Col>
                </Row>
                </Col>
                <Col md={4}>
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item"><Figure className="d-inline">
                    <Figure.Image
                      width={80}
                      height={80}
                      alt="kevin"
                      src={kevin}
                      roundedCircle
                      className="d-inline"
                    />
                  </Figure></li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4>kevin</h4></div>
                    <div><p className="text-muted small">Development Team</p></div>
                  </Stack></li>
                </ul>
                <Row>
                  <Col md={12}>
                    <Figure.Caption>
                      <em>"As a child I wanted to be, a doctor"</em>
                    </Figure.Caption>
                  </Col>
                </Row>
                </Col>
            </Row>
            <Row className="mb-5">
                  <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Messager Kambale"
                          src={Messager}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Mesager Kambale</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a software Engineer"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="kelvin Kerimu"
                          src={kelvin}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>kelvin Kerimu</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a doctor"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Bolade Victory"
                          src={bolade}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Bolade Victory</h4></div>
                        <div><p className="text-muted small">Project Manager Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a doctor"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="mb-5">
                  <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Ernest Musyoki"
                          src={Ernest}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Ernest Musyoki</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a lead singer"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                    <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Josephine Wells"
                          src={Josephine}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Josephine Wells</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, an entrepreneur"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                    <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="RinaOtey"
                          src={RinaOtey}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>RinaOtey</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <a href="https://d3js.org/" target="_blank" rel="noreferrer" className="text-decoration-none hidden-link">
                            <em>"As a child I wanted to be, a d3 master"</em>
                          </a>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                </Row>

                <Row className="mb-5">
                  <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Roshni Edwards"
                          src={Roshni}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Roshni Edwards</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a developer"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                    <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Zoe Gage "
                          src={zoe}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>zoe Mohamed</h4></div>
                        <div><p className="text-muted small">UX Design Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, an engineer"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                    <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="kirti Avila"
                          src={kirti}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>kirti Meena</h4></div>
                        <div><p className="text-muted small">Digital Marketing  Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a doctor"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                </Row>

                <Row className="mb-5">
                  <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="gladys Kiboro"
                          src={gladys}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>gladys Memon</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a doctor"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                    <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Christopher Gonzalez"
                          src={Topher}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Christopher Gonzalez</h4></div>
                        <div><p className="text-muted small">Design Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a doctor"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                    <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Justin Sabal"
                          src={Justin}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Justin Sabal</h4></div>
                        <div><p className="text-muted small">Product Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a doctor"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                </Row>

                <Row className="mb-5">
                  {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Shaishav Maisuria"
                          src={Shaishav}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Shaishav Maisuria</h4></div>
                        <div><p className="text-muted small">Data Science Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, an entrepreneur"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                    {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Jeremy Lese"
                          src={Jeremy}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Jeremy Lese</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a fighter pilot"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                    {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Aklilu Aki Bizuayehu"
                          src={Aklilu}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Aklilu "Aki" Bizuayehu</h4></div>
                        <div><p className="text-muted small">Design Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a soldier"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                </Row>

                <Row className="mb-5">
                  {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Md. Asikur Rahman"
                          src={Asikur}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Md. "Asikur" Rahman</h4></div>
                        <div><p className="text-muted small">Data Science Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a doctor"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                    {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="unhui Yvette Yang"
                          src={Yvette}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Junhui "Yvette" Yang</h4></div>
                        <div><p className="text-muted small">Design Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, an artist"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                    {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Han Li"
                          src={Han}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Han Li</h4></div>
                        <div><p className="text-muted small">Design Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a lawyer"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                </Row>

                <Row className="mb-5">
                  {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Rabin Roy Gurung"
                          src={Rabin}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Rabin "Roy" Gurung</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, an astronomer"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                    {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Derrique Baluyut"
                          src={Derrique}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Derrique Baluyut</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, an pilot"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                    {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Larry Nguyen"
                          src={Larry}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Larry Nguyen</h4></div>
                        <div><p className="text-muted small">Design Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a kung fu movie star"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                </Row>

                <Row className="mb-5">
                  {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Candidus Wisdom Wisdom"
                          src={Wisdom}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Candidus "Wisdom" Wisdom</h4></div>
                        <div><p className="text-muted small">Design Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, an artist"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                    {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Vivian Qu"
                          src={Vivian}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Vivian Qu</h4></div>
                        <div><p className="text-muted small">Product Manager</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a space woman"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                    {/* <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Siyuan Jessica Poon"
                          src={Jessica}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Siyuan "Jessica" Poon</h4></div>
                        <div><p className="text-muted small">Design Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a fashion designer"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col> */}
                </Row>
{/* 
                <Row className="mb-5">
                  <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Barath Alliyalamangalam"
                          src={Barath}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Barath Alliyalamangalam</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a doctor"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                    <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="John JP Patrick Banas"
                          src={JP}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>John "JP" Patrick Banas</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, an actor, and to sing in musicals!"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                    <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Ivan Nataren"
                          src={Ivan}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Ivan Nataren</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a machine"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                </Row> */}
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Congrakevinlations!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you found the hidden egg! However, we moved it and forgot where we put it.  Better luck next time!</Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      </Container>
    </div>
  );
}

export default TeamSection;