import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { ReactComponent as FooterLogo } from "../assets/logo/snapbrillia_footer_white_logo.svg";
import { ReactComponent as DiscordFooter } from "../assets/icons/discord_logo.svg";
import { ReactComponent as FacebookFooter } from "../assets/icons/facebook_logo.svg";
import { ReactComponent as InstagramFooter } from "../assets/icons/instagram_logo.svg";
import { ReactComponent as LinkedInFooter } from "../assets/icons/linkedin_logo.svg";
import { ReactComponent as TwitterFooter } from "../assets/icons/twitter_logo.svg";
import MediumFooter from "../assets/icons/medium_logo.png";
import RedditFooter from "../assets/icons/reddit_logo.png";
import GithubFooter from "../assets/icons/github_logo.png";
import 'bootstrap/dist/css/bootstrap.css';
import './css/footer.css';

const FooterHomePage = () => {
    return (
        <Container fluid className="footer py-5 pb-1">
          <Row>
            <Col xs={12} sm={4} md={3}>
              <FooterLogo className="mt-4" />
            </Col>
      
            <Col>
              <Link to="/" className="footer-link"><h5>Home</h5></Link>
              <Link to="/about" className="footer-link" replace><h5>About</h5></Link>
              <Link to="/community" className="footer-link" replace><h5>Community</h5></Link>
                {/* <a href="https://www.producthunt.com/upcoming/snapbrillia" target="_blank" rel="noreferrer" className="social">
                  <h5 className="footer-link">Join Full Launch Waitlist</h5>
                </a> */}
            </Col>
        
            <Col>
              <h6><u>Explore <span className="text-small"></span></u></h6>
                <a href="https://explore.snapbrillia.com" target="_blank" rel="noreferrer" className="social">
                  <h5 className="footer-link">Explore Gigs</h5>
                </a>
                <a href="https://explore.snapbrillia.com/grants" target="_blank" rel="noreferrer" className="social">
                  <h5 className="footer-link">Explore Grants</h5>
                </a>
            </Col>

            <Col className="">
              <h6><u>Business <span className="text-small"></span></u></h6>
                <a href="https://business.snapbrillia.com" target="_blank" rel="noreferrer" className="social">
                  <h5 className="footer-link">Create Gigs</h5>
                </a>
                <a href="https://explore.snapbrillia.com/grants" target="_blank" rel="noreferrer" className="social">
                  <h5 className="footer-link">Create Grants</h5>
                </a>
            </Col>
        
            <Col className="d-none">
                <a href="https://www.producthunt.com/upcoming/snapbrillia" target="_blank" rel="noreferrer" className="social">
                  <h5 className="footer-link">Privacy Policy</h5>
                </a>
                <a href="https://www.producthunt.com/upcoming/snapbrillia" target="_blank" rel="noreferrer" className="social">
                  <h5 className="footer-link">Terms &amp; Conditions</h5>
                </a>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={{ span: 4, offset: 8 }} md={{ span: 6, offset: 6 }} className="d-none d-sm-block">
              <ul className="list-group list-group-horizontal-md float-end pe-5">
                  <li className="footer list-group-item flex-fill">
                    <a href="https://discord.gg/vJuPXu86vS" target="_blank" rel="noreferrer" className="social">
                      <DiscordFooter className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://www.reddit.com/r/snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <img src={RedditFooter} alt="snapbrillia reddit link" className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://github.com/snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <img src={GithubFooter} alt="snapbrillia github link" className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://linkedin.com/company/snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <LinkedInFooter className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://medium.com/@snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <img src={MediumFooter} alt="snapbrillia medium link" className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://twitter.com/snapbrilliaInc/" target="_blank" rel="noreferrer" className="social">
                      <TwitterFooter className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://facebook.com/snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <FacebookFooter className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://instagram.com/snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <InstagramFooter className="footer_social_icons" />
                    </a>
                  </li>
                </ul>
            </Col>
            <Col xs={12} className="d-block d-sm-none mt-5">
                <ul className="list-group list-group-horizontal">
                  <li className="footer list-group-item flex-fill">
                    <a href="https://discord.gg/vJuPXu86vS" target="_blank" rel="noreferrer" className="social">
                      <DiscordFooter className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://www.reddit.com/r/snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <img src={RedditFooter} alt="snapbrillia reddit link" className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://github.com/snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <img src={GithubFooter} alt="snapbrillia github link" className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://linkedin.com/company/snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <LinkedInFooter className="footer_social_icons" />
                    </a>
                  </li>
                </ul>
            </Col>
            <Col xs={12} className="d-block d-sm-none">
                <ul className="list-group list-group-horizontal">
                <li className="footer list-group-item flex-fill">
                    <a href="https://medium.com/@snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <img src={MediumFooter} alt="snapbrillia medium link" className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://twitter.com/snapbrilliaInc" target="_blank" rel="noreferrer" className="social">
                      <TwitterFooter className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://facebook.com/snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <FacebookFooter className="footer_social_icons" />
                    </a>
                  </li>
                  <li className="footer list-group-item flex-fill">
                    <a href="https://instagram.com/snapbrillia/" target="_blank" rel="noreferrer" className="social">
                      <InstagramFooter className="footer_social_icons" />
                    </a>
                  </li>
                </ul>
            </Col>
          </Row>
          <Row className="mt-5 mb-0">
            <Col xs={12} md={{ span: 4, offset: 4 }}>
              <p className="copyright-text text-center">&copy; COPYRIGHT SNAPBRILLIA 2024</p> 
            </Col>
          </Row>
      </Container>
    );
  };
  
  export default FooterHomePage;