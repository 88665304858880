import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import FundedImage from "../../assets/illustrations/snapbrillia_bounties_home_illustration.png";
import PostGigNowButton from "../../shared/buttons/postGigNow";
import "bootstrap/dist/css/bootstrap.css";
import "../../shared/css/typography.css";
import "../../shared/css/style.css";

const GetFundedHomePage = () => {
  return (
    <Container fluid className="mt-6 mb-5">
      <Row className="mt-6">
        <Col xs={12} md={7} className="mt-6 mb-2 ps-5">
          <h2 className="mb-4 bold">Trusted by Impact Companies and Founders</h2>
          <h3 className="sub-text mb-5 lh-sm">
            <ul>
              <li>
                <figure>
                  <blockquote className="blockquote home_quote">
                    <p>"Snapbrillia helped us hire two amazing professionals we needed in less than a week."</p>
                  </blockquote>
                  <figcaption className="blockquote-footer home_quote">
                    Clint, CEO at <cite title="Tangent.art">Tangent.art</cite>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <blockquote className="blockquote home_quote">
                    <p>"We met and onboarded so many amazing people so quickly. We had to tell the team at Snapbrillia to pause on sending us more people."</p>
                  </blockquote>
                  <figcaption className="blockquote-footer home_quote">
                    Spencer, Founder at <cite title="Mirai Studios">Mirai Studios</cite>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <blockquote className="blockquote home_quote">
                    <p>"Our project was done in half the time. I am very happy to have found this resource."</p>
                  </blockquote>
                  <figcaption className="blockquote-footer home_quote">
                    Simon, CEO at <cite title="DirectEd">DirectEd</cite>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <blockquote className="blockquote home_quote">
                    <p>"If you are looking for performers don't hesitate to work with this platform. They are super efficient with all the resources and deliver on the work."</p>
                  </blockquote>
                  <figcaption className="blockquote-footer home_quote">
                    Aaron, CEO at <cite title="Artboardz">Artboardz</cite>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </h3>
          <a
            href="https://business.snapbrillia.com/bounties"
            target="_blank"
            className="social"
            rel="noreferrer"
          >
            <PostGigNowButton />
          </a>
        </Col>
        <Col xs={12} md={5} className="ps-5">
          <img
            src={FundedImage}
            alt="Snapbrillia Funding"
            className="img-fluid"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default GetFundedHomePage;
