import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import Facebook  from "../../assets/companies/facebook_logo.png";
import Google  from "../../assets/companies/google_logo.png";
import Netflix  from "../../assets/companies/netflix_logo.png";
import Reddit  from "../../assets/companies/reddit_logo.png";
import Twitter  from "../../assets/companies/twitter_logo.png";

const BuiltFor = () => {
    const BuiltForHeadline = "Built for leading companies";
    // const Hero_Image = FundPublic;
    return (
      <Container fluid className="mb-5">
        <Row className="gy-0 justify-content-md-center">
          <Col md={12} className="mt-3 mb-4">
            <h2 className="bold text-center">
                {BuiltForHeadline}
            </h2>
          </Col>
          <Col xs={12} md={4} className="mb-5">
            <ul class="list-group list-group-horizontal justify-content-center">
                <li className="list-group-item"><img src={Facebook} alt="Snapbrillia Facebook" /></li>
                <li className="list-group-item"><img src={Google} alt="Snapbrillia Google" /></li>
                <li className="list-group-item"><img src={Netflix} alt="Snapbrillia Netflix" /></li>
                <li className="list-group-item"><img src={Reddit} alt="Snapbrillia Reddit" /></li>
                <li className="list-group-item"><img src={Twitter} alt="Snapbrillia Twitter" /></li>
            </ul>
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default BuiltFor;