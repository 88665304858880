import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import BountiesImage from "../../assets/illustrations/snapbrillia_get_funded_illustration.png";
import PostHiringGigButton from "../../shared/buttons/postHiringGig";
import "bootstrap/dist/css/bootstrap.css";
import "../../shared/css/typography.css";
import "../../shared/css/style.css";

const BountiesHomePage = () => {
  return (
    <Container fluid className="mt-6 mb-5">
      <Row className="mt-6">
        <Col xs={12} md={5} className="ps-5">
          <img
            src={BountiesImage}
            alt="Snapbrillia Gigs"
            className="img-fluid"
          />
        </Col>
        <Col xs={12} md={7} className="mt-6 mb-2 ps-5">
          <h2 className="mb-4 bold">Meet Talented People Around the World</h2>
          <h3 className="sub-text mb-5 lh-sm">
            Post gigs in seconds and get matched and quickly onboard people who can help you in 
            hours instead of months. We source and engage from a global talent pool of over 910 
            million people for any job anywhere.
          </h3>
          <a
            href="https://business.snapbrillia.com"
            target="_blank"
            className="social"
            rel="noreferrer"
          >
            <PostHiringGigButton />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default BountiesHomePage;
