import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import '../css/businessPage.css';
import StartButton from '../../shared/buttons/getStarted';

const BusinesspageHero = () => {
    const Headline_Subtitle = "Hire the best professionals. Scale your business quickly and efficiently.";
    const Headline_Secondary = "Achieve success!";
    // const Hero_Image = FundPublic;
    return (
      <Container fluid className="mb-5 business_bg mb-n5">
        <Row className="gy-0">
          <Col xs={12} md={9} className="mt-4 ps-5">
            <h1 className="primary-text-color bold mb-5">
              {Headline_Subtitle}<br/>
              {Headline_Secondary}
            </h1>
            <a href="https://business.snapbrillia.com" className="social">
              <StartButton />
            </a>
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default BusinesspageHero;