import { Container, Row, Col } from "react-bootstrap";
import CommunityPeople  from "../../assets/illustrations/snapbrillia_join_community_illustration.png";
import JoinCommunityButton from '../../shared/buttons/joinCommunity';
import 'bootstrap/dist/css/bootstrap.css';
import '../css/about.css';
import '../../shared/css/typography.css';


function JoinCommunity() {
  return (
    <div className="About">
      <Container fluid>
        <Row className="mt-5 mb-3">
          <Col xs={12} md={7} className="mt-5 mb-2 ps-5">
            <h2 className="mb-4 bold">Join the Community!</h2>
            <a href="https://discord.gg/vJuPXu86vS" target="_blank" rel="noreferrer" className="social">
              <JoinCommunityButton />
            </a>
          </Col>
          <Col xs={12} md={5} className="ps-5">
            <img src={CommunityPeople} alt="Snapbrillia Community" className="img-fluid"/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default JoinCommunity;