import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Stack from 'react-bootstrap/Stack';
import { ReactComponent as Discord } from "../../assets/icons/black_discord_icon.svg";
import { ReactComponent as Facebook } from "../../assets/icons/black_facebook_icon.svg";
import { ReactComponent as Instagram } from "../../assets/icons/black_instagram_icon.svg";
import { ReactComponent as LinkedIn } from "../../assets/icons/black_linkedin_icon.svg";
import { ReactComponent as Twitter } from "../../assets/icons/black_twitter_icon.svg";
import { ReactComponent as Medium } from "../../assets/icons/black_medium_icon.svg";
import Reddit from "../../assets/icons/black_reddit_icon.png";
import Github from "../../assets/icons/black_github_icon.png";
import 'bootstrap/dist/css/bootstrap.css';
import '../css/community.css';

const SocialNetworks = () => {
    return (
      <Container fluid className="mb-5">
        <Row>
        <Col md={8} className="mt-3 mb-2 ps-5 pe-5">
            <h2 className="primary-text-color bold">
                Social Networks
            </h2>
          </Col>    
        </Row>  
        <Row className="gy-0  ps-5 pe-5">
          <Col md={3} className="mt-3 mb-2 ">
            <a href="https://discord.gg/vJuPXu86vS" target="_blank" rel="noreferrer" className="social">
              <div className="social_links">
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item">
                    <Discord className="social_icons" />
                  </li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4 className="lead">Discord</h4></div>
                    <div><h5><u>@snapbrillia</u></h5></div>
                  </Stack></li>
                </ul>
              </div>
            </a>
          </Col>
          <Col md={3} className="mt-3 mb-2">
            <a href="https://www.reddit.com/r/snapbrillia/" target="_blank" rel="noreferrer" className="social">
              <div className="social_links">
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item">
                    <img src={Reddit} alt="snapbrillia reddit link" className="social_icons" />
                  </li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4 className="lead">Reddit</h4></div>
                    <div><h5><u>@snapbrillia</u></h5></div>
                  </Stack></li>
                </ul>
              </div>
            </a>
          </Col>
          <Col md={3} className="mt-3 mb-2">
            <a href="https://github.com/snapbrillia/" target="_blank" rel="noreferrer" className="social">
              <div className="social_links">
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item">
                    <img src={Github} alt="snapbrillia reddit link" className="social_icons" />
                  </li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4 className="lead">Github</h4></div>
                    <div><h5><u>@snapbrillia</u></h5></div>
                  </Stack></li>
                </ul>
              </div>
            </a>
          </Col>
          <Col md={3} className="mt-3 mb-2">
            <a href="https://linkedin.com/company/snapbrillia/" target="_blank" rel="noreferrer" className="social">
              <div className="social_links">
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item">
                    <LinkedIn className="social_icons" />
                  </li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4 className="lead">LinkedIn</h4></div>
                    <div><h5><u>@snapbrillia</u></h5></div>
                  </Stack></li>
                </ul>
              </div>
            </a>
          </Col>
          <Col md={3} className="mt-3 mb-2">
            <a href="https://medium.com/@snapbrillia/" target="_blank" rel="noreferrer" className="social">
              <div className="social_links">
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item">
                    <Medium className="social_icons" />
                  </li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4 className="lead">Medium</h4></div>
                    <div><h5><u>@snapbrillia</u></h5></div>
                  </Stack></li>
                </ul>
              </div>
            </a>
          </Col>
          <Col md={3} className="mt-3 mb-2">
            <a href="https://twitter.com/snapbrillia/" target="_blank" rel="noreferrer" className="social">
              <div className="social_links">
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item">
                    <Twitter className="social_icons" />
                  </li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4 className="lead">Twitter</h4></div>
                    <div><h5><u>@snapbrillia</u></h5></div>
                  </Stack></li>
                </ul>
              </div>
            </a>
          </Col>
          <Col md={3} className="mt-3 mb-2">
            <a href="https://facebook.com/snapbrillia/" target="_blank" rel="noreferrer" className="social">
              <div className="social_links">
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item">
                    <Facebook className="social_icons" />
                  </li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4 className="lead">Facebook</h4></div>
                    <div><h5><u>@snapbrillia</u></h5></div>
                  </Stack></li>
                </ul>
              </div>
            </a>
          </Col>
          <Col md={3} className="mt-3 mb-2 ">
            <a href="https://instagram.com/snapbrillia/" target="_blank" rel="noreferrer" className="social">
              <div className="social_links">
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item">
                    <Instagram className="social_icons" />
                  </li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4 className="lead">Instagram</h4></div>
                    <div><h5><u>@snapbrillia</u></h5></div>
                  </Stack></li>
                </ul>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default SocialNetworks;